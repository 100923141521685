<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>
    <form method="post" @submit.prevent="onSubmit('frmBooking')" data-vv-scope="frmBooking" novalidate="novalidate">
      <div class="row justify-content-md-center">
        <div class="col-12 col-md-12">
          <h5 class="box-title mb-3">Configurações</h5>
          <div class="row">
            <!-- Booking Managers -->
            <div class="col-xl-6">
              <div class="card card-fullheight">
                <div class="card-body">
                  <h5 class="box-title mb-3">Administradores</h5>
                  <p>Tem controle total sobre os registros de booking</p>
                  <advanced-multiple-select
                    id-field="id"
                    labelField="name"
                    :no-options="'Buscar Funcionário'"
                    :tableFields="fields"
                    :enableStatus="1"
                    :value="managers"
                    :options="managerList"
                    v-bind:fetchOptions="managerFetchOptions"
                    @onRemoved="managerRemoved"
                    @onInput="managerSelected"
                    @rowClicked="managerSelected">
                  </advanced-multiple-select>
                </div>
              </div>
            </div>

            <!-- Booking Requesters -->
            <div class="col-xl-6">
              <div class="card card-fullheight">
                <div class="card-body">
                  <h5 class="box-title mb-3">Usuários</h5>
                  <p>Podem fazer requisições de bookings e utilizar espaços disponíveis</p>
                  <advanced-multiple-select
                    id-field="id"
                    labelField="name"
                    :no-options="'Buscar Funcionário'"
                    :tableFields="fields"
                    :enableStatus="1"
                    :value="requesters"
                    :options="requesterList"
                    v-bind:fetchOptions="requesterFetchOptions"
                    @onRemoved="requesterRemoved"
                    @onInput="requesterSelected"
                    @rowClicked="requesterSelected">
                  </advanced-multiple-select>
                </div>
              </div>
            </div>

            <!-- Booking Requesters -->
            <div class="col-xl-6">
              <div class="card card-fullheight">
                <div class="card-body">
                  <h5 class="box-title mb-3">Interessados</h5>
                  <p>Acompanham a movimentação de espaços, recebendo notificações por e-mail</p>
                  <advanced-multiple-select
                    id-field="id"
                    labelField="name"
                    :no-options="'Buscar Funcionário'"
                    :tableFields="fields"
                    :enableStatus="1"
                    :value="notifiables"
                    :options="notifiableList"
                    v-bind:fetchOptions="notifiableFetchOptions"
                    @onRemoved="notifiableRemoved"
                    @onInput="notifiableSelected"
                    @rowClicked="notifiableSelected">
                  </advanced-multiple-select>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Submit -->
        <div class="col-12 mb-5">
          <button type="button" class="btn btn btn-outline-light pull-right" @click="onSave">
            Salvar
          </button>
          <!-- <button type="button" class="btn btn-link btn-sm mt-1 pull-right" @click="onShow">
            Console
          </button> -->
        </div>
      </div>
    </form>
  </div>
</template>

<script>
// Components
import AdvancedMultipleSelect from '@/components/Forms/AdvancedMultipleSelect.vue'

// eslint-disable-next-line
import { util } from '@/utils/utils.js'

// Form
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

// Services
import UserService from '@/services/UserService'
import BookingService from '@/services/BookingService'

export default {
  name: 'BookingConfig',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('booking.new-edit_booking') + ' - %s'
    }
  },
  data () {
    return {
      isLoading: true,
      isEdit: false,
      formErrors: {},
      fullPage: true,
      submitted: false,
      //  Selected Values (Form)
      managers: [],
      requesters: [],
      notifiables: [],
      // List of options to select on select input
      managerList: [],
      requesterList: [],
      notifiableList: [],
      fields: [
        {
          name: 'id',
          title: '#',
          sortField: 'id',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'name',
          title: this.$i18n.t('origin_destination.name'),
          sortField: 'name',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'email',
          title: this.$i18n.t('origin_destination.email'),
          sortField: 'email',
          formatter: (value) => this.$util.formatEmpty(value)
        }
      ]
    }
  },
  components: {
    Loading,
    AdvancedMultipleSelect
  },
  // Apply filters to local filter
  beforeMount () {

  },
  mounted () {
    this.$store.dispatch('SetShowFilter', false)
    this.$store.dispatch('setShowSearch', false)

    this.managerFetchOptions()
    this.requesterFetchOptions()
    this.notifiableFetchOptions()
  },
  created () {
    // Load basilares data
    let promises = [
      BookingService.getManagers(),
      BookingService.getRequesters(),
      BookingService.getNotifiables()
    ]

    Promise.all(promises).then((values) => {
      // currencyInfo
      let managersRawList = values[0].data.data
      this.managers = managersRawList.map(x => {
        return {
          id: x.id,
          name: x.name,
          email: x.email,
          username: x.username,
          status: 1
        }
      })

      let requestersRawList = values[1].data.data
      this.requesters = requestersRawList.map(x => {
        return {
          id: x.id,
          name: x.name,
          email: x.email,
          username: x.username,
          status: 1
        }
      })

      let notifiablesRawList = values[2].data.data
      this.notifiables = notifiablesRawList.map(x => {
        return {
          id: x.id,
          name: x.name,
          email: x.email,
          username: x.username,
          status: 1
        }
      })

      this.isLoading = false
    }).catch(() => {
      this.isLoading = false
    })
    this.isLoading = false
  },
  methods: {
    /**
     * Triggered when the user search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    managerFetchOptions (search) {
      this.isLoading = true
      let filters = {
        'searchTerm': search
      }

      UserService.getEmployeesUser(filters).then(res => {
        this.managerList = res.data.data
        this.isLoading = false
      })
    },
    managerRemoved (val) {
    },
    managerSelected (val) {
    },
    /**
     * Triggered when the user search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    requesterFetchOptions (search) {
      this.isLoading = true
      let filters = {
        'searchTerm': search
      }

      UserService.getEmployeesUser(filters).then(res => {
        this.requesterList = res.data.data
        this.isLoading = false
      })
    },
    requesterRemoved (val) {
    },
    requesterSelected (val) {
    },
    /**
     * Triggered when the user search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    notifiableFetchOptions (search) {
      this.isLoading = true
      let filters = {
        'searchTerm': search
      }

      UserService.getEmployeesUser(filters).then(res => {
        this.notifiableList = res.data.data
        this.isLoading = false
      })
    },
    notifiableRemoved (val) {
    },
    notifiableSelected (val) {
    },
    onSave (e) {
      this.submitted = true
      this.isLoading = true

      let data = {
        managers: this.managers,
        requesters: this.requesters,
        notifiables: this.notifiables
      }

      BookingService.postConfig(data).then(response => {
        this.$router.push({ name: 'BookingIndex' })
      }).catch(err => {
        if (err.code === 400) {
          this.displayErrors(err.errors)
        }
        this.isLoading = false
      })
    },
    onShow () {
    }
  },
  computed: {}
}
</script>

<style scoped>
  .swal2-popup {
    width: inherit !important;
  }

  .card-body {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .md-form-control {
    height: 30px;
  }

  .v-select.md-form-control {
    height: 35px;

  }

  td.editable {
    padding: 0;
    vertical-align: middle;
  }

  td.editable input {
    background-color: inherit;
    border: 0;
    width: 100%;
    min-height: 33px;
    box-sizing: border-box;
  }

  .card-subtitle {
    margin-top: 15px;
  }

</style>
